import { Observable, of as observableOf } from 'rxjs';
import { LoadValidationRulesResult, ValidationRule } from 'state-domains/domain';
import { convertToCamel, sendRetrieveRequest } from 'state-domains/utils';
import { buildValidationRulesUrl } from 'state-domains/network';
import { switchMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';

export const loadValidationRulesShim = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: ValidationRule[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const obs = sendRetrieveRequest(buildValidationRulesUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<LoadValidationRulesResult>) => {
            const result = convertToCamel<ValidationRule[]>(response.ValidationRule);
            return observableOf({
                result,
                actionName,
                processResultFunction,
            });
        }),
    );
};
